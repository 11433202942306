/**=====================
   Ratio CSS Start
==========================**/
.ratio_40 {
    .bg-size {
        &:before {
            padding-top: 40%;
            content: "";
            display: block;
        }
    }
}

.ratio_45 {
    .bg-size {
        &:before {
            padding-top: 45%;
            content: "";
            display: block;
        }
    }
}

.ratio2_1 {
    .bg-size {
        &:before {
            padding-top: 50%;
            content: "";
            display: block;
        }
    }
}

.ratio_55 {
    .bg-size {
        &:before {
            padding-top: 55%;
            content: "";
            display: block;
        }
    }
}

.ratio_59 {
    .bg-size {
        &:before {
            padding-top: 59%;
            content: "";
            display: block;
        }
    }
}

.ratio2_3 {
    .bg-size {
        &:before {
            padding-top: 60%;
            content: "";
            display: block;
        }
    }
}

.ratio3_2 {
    .bg-size {
        &:before {
            padding-top: 66.66%;
            content: "";
            display: block;
        }
    }
}

.ratio_90 {
    .bg-size {
        &:before {
            padding-top: 93%;
            content: "";
            display: block;
        }
    }
}

.ratio_landscape {
    .bg-size {
        &:before {
            padding-top: 75%;
            content: "";
            display: block;
        }
    }
}

.ratio_square {
    .bg-size {
        &:before {
            padding-top: 100%;
            content: "";
            display: block;
        }
    }
}

.ratio_asos {
    .bg-size {
        &:before {
            padding-top: 127.7777778%;
            content: "";
            display: block;
        }
    }
}

.ratio_portrait {
    .bg-size {
        &:before {
            padding-top: 150%;
            content: "";
            display: block;
        }
    }
}

.ratio1_2 {
    .bg-size {
        &:before {
            padding-top: 200%;
            content: "";
            display: block;
        }
    }
}

.b_size_content {
    background-size: contain !important;
    background-repeat: no-repeat;
}

/**=====================
     Ratio CSS End
  ==========================**/
